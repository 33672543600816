import {
    ComponentType,
    useEffect,
    useState,
    cloneElement,
    useMemo,
    useCallback,
} from "react"

import { truncateText, isTextOverLimit } from "./Core/Utils.tsx"

import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import NewsPostThumbnail from "https://framer.com/m/News-Post-Thumbnail-oUia.js@jPv2UgI9Hd1ubKg5CVUj"
import ButtonTag from "https://framer.com/m/Button-Tag-CsYo.js@8EqX8fAAcN9kPX0wOGgv"
// Learn more: https://www.framer.com/docs/guides/overrides/

const useStore = createStore({
    background: "#0099FF",
})

interface IPost {
    id: number
    title: string
    slug: string
    excerpt: string | null
    content: string
    quote: string | null
    tags: string[]
    editory: string
    place: string | null
    imageUrl: string | null
    imageAlt: string | null
    imageDescription: string | null
    videoUrl: string | null
    releaseId: string
    publishedAt: Date
    website: string | null
    deletePost: boolean
    createdAt: Date
    error?: boolean
}

interface IComponentPost {
    key: string | number
    image: string
    category: string
    text: string
    tooltip?: string
    link: string
    highlight: boolean
    member: boolean
}

export function withPostSlider(Component): ComponentType {
    return (props: any) => {
        const [slots, setSlots] = useState<IComponentPost[]>([])
        useEffect(() => {
            fetch(
                "https://mundo-mkt.vercel.app/api/institutional-posts?limit=12"
            )
                .then((o) => o.json() as Promise<{ data: IPost[] }>)
                .then(({ data }) => {
                    setSlots(
                        data.map((o) => ({
                            key: o.id,
                            image: o.imageUrl,
                            category: "",
                            text: o.title,
                            link:
                                "https://mundodomarketing.com.br/institucional?slug=" +
                                o.slug,
                            highlight: false,
                            member: false,
                        }))
                    )
                })
        }, [])

        if (!slots.length) {
            return null
        }

        return (
            <Component
                {...props}
                slots={slots.map((o) => (
                    <NewsPostThumbnail {...o} variant="Api" />
                ))}
            />
        )
    }
}

interface IMetadata {
    limit: number
    offset: number
    nextPage: number
    page: number
    hasNextPage: boolean
    total: number
}

const usePageState = createStore({
    loading: true,
    hasMore: true,
    nextPage: 1,
    loadPage: null,
})

export function withPaginationButton(Component): ComponentType {
    return (props: any) => {
        const [pageState, setPageState] = usePageState()

        if (!pageState.hasMore) {
            return false
        }

        return (
            <Component
                onClick={() => pageState.loadPage?.(pageState.nextPage)}
                variant={pageState.loading ? "Loading" : "Default"}
            />
        )
    }
}

export function withPostListWithPagination(Component): ComponentType {
    return (props: any) => {
        const [posts, setPosts] = useState<IComponentPost[]>([])
        const [metadata, setMetadata] = useState<IMetadata>({
            limit: 10,
            offset: 0,
            nextPage: 0,
            page: 0,
            hasNextPage: true,
            total: 0,
        })
        const [pageState, setPageState] = usePageState()

        const loadPage = useCallback((page: number, { limit = 10 } = {}) => {
            setPageState((o) => ({
                ...o,
                loading: true,
            }))
            fetch(
                "https://mundo-mkt.vercel.app/api/institutional-posts?limit=" +
                    limit +
                    "&page=" +
                    page
            )
                .then(
                    (o) =>
                        o.json() as Promise<{
                            data: IPost[]
                            metadata: IMetadata
                        }>
                )
                .then((result) => {
                    setPosts((posts) => {
                        return [
                            ...posts,
                            ...result.data.map((o) => ({
                                key: o.id,
                                image: o.imageUrl,
                                category: "",
                                text: truncateText(o.title, 90),
                                tooltip: isTextOverLimit(o.title, 90)
                                    ? o.title
                                    : undefined,
                                link:
                                    "https://mundodomarketing.com.br/institucional?slug=" +
                                    o.slug,
                                highlight: false,
                                member: false,
                            })),
                        ]
                    })

                    setMetadata(result.metadata)

                    setPageState((o) => ({
                        ...o,
                        nextPage: result.metadata.nextPage,
                        hasMore: result.metadata.hasNextPage,
                    }))
                })
                .finally(() => {
                    setPageState((o) => ({
                        ...o,
                        loading: false,
                    }))
                })
        }, [])

        useEffect(() => {
            loadPage(1)
            setPageState((o) => ({
                ...o,
                loadPage,
            }))
        }, [])

        if (!posts.length) {
            return null
        }

        return (
            <Component {...props}>
                {posts.map((o) => (
                    <NewsPostThumbnail
                        {...o}
                        style={{ maxWidth: "100%" }}
                        variant="Medium"
                    />
                ))}
            </Component>
        )
    }
}

const useReleaseId = () => {
    const [releaseId, setReleaseId] = useState<string>(null)
    useEffect(() => {
        const search = new URLSearchParams(window.location.search)
        const releaseid = search.get("releaseid")
        console.log("releaseid", releaseid)
        if (releaseid) {
            setReleaseId(releaseId)
        } else {
            setReleaseId(null)
        }
    }, [])

    return { releaseId, setReleaseId }
}

export function withReleaseIdRedirect(Component): ComponentType {
    return (props) => {
        const { releaseId, setReleaseId } = useReleaseId()

        useEffect(() => {
            if (releaseId) {
                console.log("try redirect to releaseId", releaseId)

                fetch(
                    "https://mundo-mkt.vercel.app/api/institutional-posts/releaseId/" +
                        releaseId
                )
                    .then((o) => o.json() as Promise<IPost>)
                    .then((o) => {
                        if (
                            window.location.hostname ===
                                "mundodomarketing.com.br" &&
                            o.slug
                        ) {
                            window.location.href =
                                "/institutional?slug=" + o.slug
                        }
                    })
                    .catch(() => {
                        setReleaseId(null)
                    })
            }
        }, [releaseId])

        if (!releaseId) {
            return <Component {...props} />
        }

        return null
    }
}

const useSlug = () => {
    const [slug, setSlug] = useState<string>()
    useEffect(() => {
        const search = new URLSearchParams(window.location.search)
        const _slug = search.get("slug")
        if (!_slug) {
            if (window.location.hostname === "mundodomarketing.com.br") {
                window.location.href = "/404"
            }
        } else {
            setSlug(_slug)
        }
    }, [])

    return slug
}

export function withPostSlug(Component): ComponentType {
    return (props) => {
        const slug = useSlug()

        if (!slug) {
            return null
        }

        return <Component {...props} slug={slug} />
    }
}

export function withPost(Component): ComponentType {
    return (props) => {
        return <Component {...props} />
    }
}

const cache = {}

const useCurrentPost = () => {
    const [post, setPost] = useState<IPost>()
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)
    const slug = useSlug()

    useEffect(() => {
        if (!slug) return

        setLoading(true)

        const LoadPost = (Data: Promise<IPost>) => {
            Data.then((o) => {
                setPost(o)
                setLoading(false)
                setError(false)
                if (o.error) {
                    throw new Error(o.error)
                }
            }).catch((o) => {
                setLoading(false)
                setError(true)
                if (window.location.hostname === "mundodomarketing.com.br") {
                    window.location.href = "/404"
                }
                cache[slug] = null
            })
        }

        if (cache[slug]) {
            const o = cache[slug]
            if (new Date().getTime() < o.date) {
                LoadPost(o.post)
                return
            }
        }

        cache[slug] = {
            date: new Date().getTime() + 5 * 60 * 1000,
            post: fetch(
                "https://mundo-mkt.vercel.app/api/institutional-posts/slug/" +
                    slug
            ).then((o) => o.json() as Promise<IPost>),
        }

        LoadPost(cache[slug].post)
    }, [slug])

    return {
        post,
        error,
        loading,
    }
}

export function withContent(Component): ComponentType {
    return (props: any) => {
        const { post, loading } = useCurrentPost()

        if (loading || !post) {
            return null
        }

        return (
            <Component
                {...props}
                style={{
                    ...props?.style,
                    // "--framer-text-color": "#e1e1e6",
                    // color: "#e1e1e6",
                }}
                content={post.content}
            />
        )
    }
}

export function withTags(Component): ComponentType {
    return (props) => {
        const { post, loading } = useCurrentPost()

        if (loading || !post) {
            return null
        }

        return (
            <>
                {post.tags?.map((tag) => (
                    <Component {...props} key={tag} title={tag} />
                ))}
            </>
        )
    }
}

export function withTagsContainer(Component): ComponentType {
    return (props: any) => {
        const { post, loading } = useCurrentPost()

        console.log("withTagsContainer", post, loading)

        if (loading || !post) {
            return null
        }

        return (
            <Component
                {...props}
                children={post.tags?.map((tag) => (
                    <ButtonTag {...props} key={tag} title={tag} />
                ))}
            />
        )
    }
}
